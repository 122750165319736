import React from "react"
import PropTypes from "prop-types"

import "./KeyFeatures.scss"

const featuresMap = [
  {
    id: "d3",
    name: "D3",
    category: "Mapping and Live Updating",
    logos: ["d3.svg"]
  },
  {
    id: "ember",
    name: "Ember JS",
    category: "Modern App Interface",
    logos: ["ember.svg"]
  },
  {
    id: "google-cloud-translation",
    name: "Google Cloud Translation API",
    category: "Translation",
    logos: ["google-cloud-translation.png"]
  },
  {
    id: "google-cloud-vision",
    name: "Google Cloud Vision API",
    category: "Image Recognition",
    logos: ["google-cloud-vision.png"]
  },
  {
    id: "google-maps",
    name: "Google Maps API",
    category: "Drawing Geo Boundaries",
    logos: ["google-maps.png"]
  },
  {
    id: "highcharts",
    name: "Highcharts",
    category: "Data Aggregation and Charting",
    logos: ["highcharts.svg"]
  },
  {
    id: "hosting",
    name: "Heroku, Postgres",
    category: "Hosting",
    logos: ["heroku.svg", "postgres.png"]
  },
  {
    id: "jwt",
    name: "JSON Web Tokens",
    category: "Authentication",
    logos: ["jwt.svg"]
  },
  {
    id: "monitoring",
    name: "Bugsnag",
    category: "Error Monitoring",
    logos: ["bugsnag.png"]
  },
  {
    id: "netsuite",
    name: "NetSuite",
    category: "Billing & Support Framework",
    logos: ["netsuite.png"]
  },
  {
    id: "rails",
    name: "Ruby on Rails",
    category: "Core App Platform & API",
    logos: ["rails.svg"]
  },
  {
    id: "react",
    name: "React JS",
    category: "Frontend Javascript",
    logos: ["reactjs.svg"]
  },
  {
    id: "reliability-ranking",
    name: "Custom Intellectual Property",
    category: "Source and Reliability Ranking",
    logos: ["custom.svg"]
  },
  {
    id: "statuspage",
    name: "StatusPage",
    category: "Maintenance Framework",
    logos: ["statuspage.svg"]
  },
  {
    id: "stripe",
    name: "Stripe",
    category: "Subscriptions",
    logos: ["stripe.svg"]
  },
  {
    id: "swagger",
    name: "Swagger",
    category: "API Specifications",
    logos: ["swagger.png"]
  },
  {
    id: "tintri",
    name: "Tintri",
    category: "Disaster Recovery Integration",
    logos: ["tintri.jpg"]
  },
  {
    id: "twitter",
    name: "Twitter API",
    category: "Initial Data Source",
    logos: ["twitter.svg"]
  },
  {
    id: "vmware",
    name: "VMware vCloud Director",
    category: "Datacenter Metrics Integration",
    logos: ["vmware.svg"]
  }
]

const getChunkedFeatures = features => {
  const chunked = []
  let index = 0
  while(index < features.length) {
    chunked.push(features.slice(index, index+3))
    index += 3
  }
  return chunked
}

const KeyFeatures = ({ features }) => {
  if(!features) return null
  const chunked = getChunkedFeatures(features)
  return (
    <div className="key-features">
      {chunked.map(threeFeatures => {
        const emptyBlocks = 3 - threeFeatures.length
        return (
          <div className="key-features-row" key={threeFeatures[0]}>
            <div className="side-content" />
            {threeFeatures.map(featureId => {
              const feature = featuresMap.find(f => f.id === featureId)
              return (
                <div key={featureId} className="content">
                  <div className="logo">
                    {feature.logos.map(logo =>
                      <img key={logo} src={require(`src/assets/images/features/${logo}`).default} alt={feature.name} />
                    )}
                  </div>
                  <h5>{feature.category}</h5>
                  <span>{feature.name}</span>
                </div>
              )
            })}
            {[...Array(emptyBlocks).keys()].map((k) => (
                <div key={k} className="content" />
              ))}
            <div className="side-content" />
          </div>
        )
      })}
    </div>
  )
}

KeyFeatures.defaultProps = {
  features: []
}

KeyFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
}

export default KeyFeatures