import React from "react"
import { Link, graphql } from "gatsby"
import pluralize from "pluralize"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"
import KeyFeatures from "src/components/KeyFeatures"

import calendar from "src/assets/images/calendar.svg"
import user from "src/assets/images/user.svg"
import users from "src/assets/images/users.svg"
import checkbox from "src/assets/images/checkbox.svg"
import hyperlink from "src/assets/images/hyperlink.svg"
import logoMark from "src/assets/images/vaporware-logo-mark.svg"
import "./workTemplate.scss"

const engStyle = (userCount) => {
  if(parseInt(userCount) > 1) {
    return {backgroundImage: `url(${users})`}
  }
  return {backgroundImage: `url(${user})`}
}

const WorkTemplate = ({ data }) => {
  const { page } = data
  const { frontmatter, html } = page
  const { meta, hero, workSummary } = frontmatter

  return (
    <Layout meta={meta}>
      <div className="work-template">
        <PageHero {...hero} />
        <div className="work-details">
          <div className="container">
            <div className="row">
              <div className="col-md offset-md-2">
                <h6>Engagement Details</h6>
                <ul>
                  <li style={{ backgroundImage: `url(${calendar})` }}>
                    {workSummary.engagement.time}
                  </li>
                  {workSummary.engagement.product > 0 && (
                    <li style={engStyle(workSummary.engagement.product)}>
                      {" "}
                      {pluralize(
                        "Product Managers",
                        workSummary.engagement.product,
                        true
                      )}
                    </li>
                  )}
                  {workSummary.engagement.design > 0 && (
                    <li style={engStyle(workSummary.engagement.design)}>
                      {pluralize(
                        "Designer",
                        workSummary.engagement.design,
                        true
                      )}
                    </li>
                  )}
                  {workSummary.engagement.development > 0 && (
                    <li style={engStyle(workSummary.engagement.development)}>
                      {" "}
                      {pluralize(
                        "Developer",
                        workSummary.engagement.development,
                        true
                      )}
                    </li>
                  )}
                  {workSummary.engagement.sre > 0 && (
                    <li style={engStyle(workSummary.engagement.sre)}>
                      {pluralize(
                        "Site Reliability Engineer",
                        workSummary.engagement.sre,
                        true
                      )}
                    </li>
                  )}
                </ul>
              </div>
              <div className="col-md">
                <h6>Services</h6>
                <ul>
                  {workSummary.services.plan && (
                    <Link to="/services/plan">
                      <li style={{ backgroundImage: `url(${checkbox})` }}>
                        Plan
                      </li>
                    </Link>
                  )}
                  {workSummary.services.prototype && (
                    <Link to="/services/prototype">
                      <li style={{ backgroundImage: `url(${checkbox})` }}>
                        Prototype
                      </li>
                    </Link>
                  )}
                  {workSummary.services.produce && (
                    <Link to="/services/produce">
                      <li style={{ backgroundImage: `url(${checkbox})` }}>
                        Produce
                      </li>
                    </Link>
                  )}
                  {/* {workSummary.services.protect && (
                    <Link to="/services/protect">
                      <li style={{ backgroundImage: `url(${checkbox})` }}>
                        Protect
                      </li>
                    </Link>
                  )} */}
                </ul>
              </div>
              {workSummary.more &&
                <div className="col-md">
                  <h6>Visit</h6>
                  <ul>
                    <li style={{ backgroundImage: `url(${hyperlink})` }}>
                      <a href={workSummary.more.link} target="_blank">
                        {workSummary.more.title}
                      </a>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <article className="work-body">
          <Content html={html} />
          <KeyFeatures features={workSummary.features} />
          <div className="work-mark">
            <Link to="/">
              <img src={logoMark} alt="Vaporware" />
            </Link>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default WorkTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
      frontmatter {
        workSummary {
          engagement {
            time
            product
            design
            development
            sre
          }
          services {
            plan
            prototype
            produce
            protect
          }
          more {
            title
            link
          }
          features
        }
      }
    }
  }
`
